import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles
  // withStyles
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
// import ApexCharts from 'apexcharts'
import PerfectScrollbar from 'react-perfect-scrollbar'
// import { openInNewTab } from 'src/utils/general'
import LoadingCard from 'src/components/LoadingCard'
// import { fixedNum, europeNum } from 'src/utils/general'
import { basicColors } from 'src/theme'
import { returnDuration } from '../utils'
// import { useSnackbar } from 'notistack'

import {
  Info as InfoIcon,
  Link as LinkIcon
} from 'react-feather'
import { anomaliesLimit } from 'src/slices/pv/plantView'
import { alog } from 'src/utils/apioLog'

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
    // maxHeight: 780
  },
  anomalyTable: {
    minWidth: 720
  },
  anomalyCell: {
    cursor: 'pointer',
    fontSize: '0.78rem'
  },
  cicle: {
    color: theme.palette.primary.main
  },
  popoverContainer: {
    width: 360,
    backgroundColor: basicColors.background,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  paper: {
    maxWidth: 700,
    // backgroundColor: basicColors.background,
    padding: theme.spacing(2)
  }
}))

// funzione che torna true o false per il valore checked della checkbox di tutte le anomalie
// in base alla selezione o deselezione sulle righe
const shouldBeIntermediate = (rows) => {
  const oneFalse = rows.findIndex((anomaly) => anomaly.selected === false)
  const oneTrue = rows.findIndex((anomaly) => anomaly.selected === true)
  // controllo se c'è almeno un'anomalia selezionata ed una non selezionata allora è interminato
  if (oneFalse === -1 || oneTrue === -1) {
    return false
  } else {
    //  if (oneFalse > -1 && oneTrue > -1)
    return true
  }
}

// funzione che torna true o false per il valore checked della checkbox di tutte le anomalie
// in base alla selezione o deselezione sulle righe
const shouldAllBeChecked = (rows) => {
  const oneFalse = rows.findIndex((anomaly) => anomaly.selected === false)
  const oneTrue = rows.findIndex((anomaly) => anomaly.selected === true)
  // controllo se c'è almeno un'anomalia selezionata ed una non selezionata allora è interminato
  if (oneFalse === -1) {
    // sono tutte true
    return true
  } else if (oneTrue === -1) {
    // sono tutte false
    return false
  } else {
    return true
  }
}

// filtri base delle anomalie
const basicFilters = {
  identifier: [],
  name: [],
  message: [],
  severity: [
    {
      label: '1',
      value: 1,
      selected: false
    },
    {
      label: '2',
      value: 2,
      selected: false
    },
    {
      label: '3',
      value: 3,
      selected: false
    },
    {
      label: '4',
      value: 4,
      selected: false
    },
    {
      label: '5',
      value: 5,
      selected: false
    }
  ],
  durationOperator: '>',
  durationChoice: '',
  duration: [],
  // duration: {
  //   operator: '>',
  //   value: null
  // },
  externalUrl: ''
}

// componente principale
const AnomaliesListView = ({
  className,
  anomaliesList,
  getMoreAnomalies,
  totalAnomalies,
  limit,
  onRowClick,
  onSelectedChanged,
  onFilterChange,
  periodHasChanged,
  ...rest
}) => {
  // console.log('anomaliesList =>', anomaliesList)
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // mi preparo i dati per la tabella
  const [skip, setSkip] = useState(0)
  const [endPaginationReached, setEndPaginationReached] = useState(false)
  const [isGettingPagination, setIsGettingPagination] = useState(false)
  const [anomaliesRows, setAnomaliesRows] = useState(JSON.parse(JSON.stringify(anomaliesList)))
  // console.log('anomaliesRows =>', anomaliesRows)
  const [filters, setFilters] = useState(basicFilters)
  const [filtersToSend, setFiltersToSend] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const [nothingHasChanged, setNothingHasChanged] = useState(true)

  // funzione che ritorna i filtri
  const returnDefaultFilters = (anomalies, prevFilters) => {
    // alog('returnDefaultFilters', null, 'anomalie')
    if (anomalies && anomalies.length > 0 && anomalies[0] !== '-') {
      const newFilters = JSON.parse(JSON.stringify(basicFilters))
      anomalies.forEach(anomaly => {
        // controllo se ho già questa categoria sennò la pusho
        const catIndex = newFilters.identifier.findIndex(cat => {
          return cat.label === `${anomaly.configuration.subCategory} (${anomaly.configuration.category})`
        })
        if (catIndex === -1 && anomaly.configuration) {
          // non ce l'ho
          newFilters.identifier.push({
            label: `${anomaly.configuration.subCategory} (${anomaly.configuration.category})`,
            value: [anomaly.configuration.identifier],
            selected: false
          })
        } else if (anomaly.configuration) {
          // ho la categoria, se non ho questo l'identifier lo pusho
          const identifierIndex = newFilters.identifier[catIndex].value.indexOf(anomaly.configuration.identifier)
          if (identifierIndex === -1) {
            // non ce l'ho lo pusho
            newFilters.identifier[catIndex].value.push(anomaly.configuration.identifier)
          }
        }
        // controllo se ho già il name
        const nameIndex = newFilters.name.findIndex(name => name.value === anomaly.configuration.name)
        if (nameIndex === -1 && anomaly.configuration) {
          // non ce l'ho
          newFilters.name.push({
            label: anomaly.configuration.name,
            value: anomaly.configuration.name,
            selected: false
          })
        }
        // controllo se c'è il message
        if (anomaly.metadata && anomaly.metadata.message) {
          const messageIndex = newFilters.message.findIndex(message => message.value === anomaly.metadata.message)
          if (messageIndex === -1) {
            // non ce l'ho
            newFilters.message.push({
              label: anomaly.metadata.message,
              value: anomaly.metadata.message,
              selected: false
            })
          }
        }
      })
      // mi prendo la duration maggiore
      const maxDuration = anomalies.reduce((acc, anomaly) => {
        if (anomaly.duration > acc) {
          return anomaly.duration
        }

        return acc
      }, 0)
      // alog('maxDuration =>', maxDuration, 'anomalie')
      // 900000 === 15 minuti
      // 60000 === 1 minuto
      if (maxDuration > 0 && maxDuration > 900000) {
        const slice = maxDuration / 4
        // newFilters.duration = [slice - 60000, slice * 2 - 60000, slice * 3 - 60000, slice * 4 - 60000]
        newFilters.durationOperator = '<'
        newFilters.duration = [
          {
            label: slice - 60000,
            value: slice - 60000,
            selected: false
          }, {
            label: slice * 2 - 60000,
            value: slice * 2 - 60000,
            selected: false
          }, {
            label: slice * 3 - 60000,
            value: slice * 3 - 60000,
            selected: false
          }, {
            label: slice * 4 - 60000,
            value: slice * 4 - 60000,
            selected: false
          }
        ]
      } else {
        // newFilters.duration = [840000, 840000 * 2, 840000 * 3, 840000 * 4]
        newFilters.durationOperator = '>'
        newFilters.duration = [
          {
            label: 840000,
            value: 840000,
            selected: false
          }, {
            label: 840000 * 2,
            value: 840000 * 2,
            selected: false
          }, {
            label: 840000 * 3,
            value: 840000 * 3,
            selected: false
          }, {
            label: 840000 * 4,
            value: 840000 * 4,
            selected: false
          }
        ]
      }
      // alog('newFilters', newFilters, 'anomalie')
      // alog('prevFilters', prevFilters, 'anomalie')
      // controllo se ci sono i prevFilters per aggiornare quelli nuovi
      if (prevFilters && prevFilters.identifier && prevFilters.identifier.length > 0) {
        newFilters.identifier.forEach((newObj) => {
          const thisIndex = prevFilters.identifier.findIndex(cat => cat.value === newObj.value)
          if (thisIndex > -1) {
            newObj.selected = prevFilters.identifier[thisIndex].selected
          }
        })
        newFilters.name.forEach((newObj) => {
          const thisIndex = prevFilters.name.findIndex(name => name.value === newObj.value)
          if (thisIndex > -1) {
            newObj.selected = prevFilters.name[thisIndex].selected
          }
        })
        newFilters.message.forEach((newObj) => {
          const thisIndex = prevFilters.message.findIndex(message => message.value === newObj.value)
          if (thisIndex > -1) {
            newObj.selected = prevFilters.message[thisIndex].selected
          }
        })
        newFilters.severity.forEach((newObj, index) => {
          const thisIndex = prevFilters.severity.findIndex(severity => severity.value === newObj.value)
          if (thisIndex > -1) {
            newObj.selected = prevFilters.severity[thisIndex].selected
          }
        })
        newFilters.durationChoice = prevFilters.durationChoice
        if (prevFilters.durationChoice) {
          newFilters.duration[prevFilters.durationChoice].selected = true
        }
        // newFilters.duration.forEach((newObj, index) => {
        //   if (newObj.value === prevFilters.duration[index].value) {
        //     newObj.selected = prevFilters.duration[index].selected
        //   }
        // })
        newFilters.durationOperator = prevFilters.durationOperator
        newFilters.externalUrl = prevFilters.externalUrl
      }

      alog('newFilters => ', newFilters, 'anomalie')
      return newFilters
    }
  }

  // funzione che apre il popper con la sezione dei filtri
  const openFilterPopper = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((prev) => !prev)
  }

  // funzione che chiude popper se clicchi fuori
  const handleClosePopover = () => {
    setOpenPopper(false)
  }

  // ogni update aggiorno la variabile delle anomalie
  useEffect(() => {
    setAnomaliesRows(JSON.parse(JSON.stringify(anomaliesList)))
    // se è stato cambiato il periodo aggiorno i filtri
    if (periodHasChanged) {
      setFilters(returnDefaultFilters(anomaliesList))
    }
    setIsGettingPagination(false)
    if (anomaliesList.length < (anomaliesLimit + 1)) {
      setSkip(0)
    }
    // return () => {}
  }, [anomaliesList, periodHasChanged])

  // funzione che fa il toogle per selzionare tutte le anomalie
  const onSelectAllAnomalies = () => {
    // console.log('onSelectAllAnomalies')
    // setIsIndeterminate(false)
    // setAreAllChecked(true)
    setAnomaliesRows((prevAnomaliesRows) => {
      const listStatus = !prevAnomaliesRows.some((anomaly) => anomaly.selected === true)
      return prevAnomaliesRows.map((anomaly) => {
        return ({
          ...anomaly,
          selected: listStatus
        })
      })
    })
    if (!anomaliesRows.every((anomaly) => anomaly.selected === true)) {
      onSelectedChanged('all')
    } else {
      onSelectedChanged('none', anomaliesList.map(anomaly => anomaly.uuid))
    }
  }

  // funzione che fa il toogle per selzionare tutte le anomalie
  const onSelectAnomaly = (event, index) => {
    event.stopPropagation()
    // event.preventDefault()
    // console.log('onSelectAnomaly, index => ', index)
    const newAnomaliesRows = JSON.parse(JSON.stringify(anomaliesRows))
    newAnomaliesRows[index].selected = !newAnomaliesRows[index].selected
    setAnomaliesRows(newAnomaliesRows)
    onSelectedChanged(newAnomaliesRows[index].selected === true ? 'add' : 'remove', newAnomaliesRows[index])
    // setIntermediateAndAll(newAnomaliesRows)
    // console.log('POST newAnomaliesRows => ', newAnomaliesRows)
  }

  // funzione che viene lanciata al click su una riga
  const handleClick = (event, row) => {
    // console.log('selected row => ', row)
    if (onRowClick) {
      onRowClick(row)
    }
  }

  // funzione che setta il select dei checkbox
  const handleCheckboxChange = (section, index) => {
    const newArray = filters[section]
    newArray[index].selected = !newArray[index].selected
    // alog('newArray =>', newArray, 'anomalie')
    setFilters({
      ...filters,
      [section]: newArray
    })
    setNothingHasChanged(false)
  }

  // funzione che setta la scelta dei radio button
  const handleRadioChange = (event) => {
    // alog('new duration choice =>', event.target.value, 'anomalie')
    let newChoice = event.target.value
    if (newChoice === filters.durationChoice) {
      newChoice = ''
    }
    setFilters({
      ...filters,
      durationChoice: newChoice
    })
    setNothingHasChanged(false)
  }

  // funzione che viene lanciata al click su applica
  const handleApplyFilters = () => {
    alog('dentro apply, filters =>', filters, 'anomalie')
    // mi setto i filtri da passare alla query
    const filtersToSend = {}
    if (filters.identifier.some(cat => cat.selected === true)) {
      filtersToSend.identifier = []
      filters.identifier.forEach(cat => {
        if (cat.selected === true) {
          filtersToSend.identifier.push(...cat.value)
        }
      })
    }
    if (filters.name.some(name => name.selected === true)) {
      filtersToSend.name = []
      filters.name.forEach(name => {
        if (name.selected === true) {
          filtersToSend.name.push(name.value)
        }
      })
    }
    if (filters.message.some(message => message.selected === true)) {
      filtersToSend.message = []
      filters.message.forEach(message => {
        if (message.selected === true) {
          filtersToSend.message.push(message.value)
        }
      })
    }
    if (filters.severity.some(cat => cat.selected === true)) {
      filtersToSend.severity = []
      filters.severity.forEach(severity => {
        if (severity.selected === true) {
          filtersToSend.severity.push(severity.value)
        }
      })
    }
    if (filters.durationChoice !== '') {
      filtersToSend.duration = {
        operator: filters.durationOperator,
        value: Number(filters.duration[filters.durationChoice].value)
      }
    }
    if (filters.externalUrl !== '') {
      filtersToSend.externalUrl = filters.externalUrl
    }
    if (onFilterChange) {
      // alog('filtersToSend =>', filtersToSend, 'anomalie')
      onFilterChange(filtersToSend)
      setFiltersToSend(filtersToSend)
      handleClosePopover()
    }
  }

  // funzione che viene lanciata al click su reset
  const handleResetFilters = () => {
    alog('dentro reset, filters =>', filters, 'anomalie')
    // resetto i filtri
    const newFilters = JSON.parse(JSON.stringify(filters))
    Object.keys(newFilters).forEach(key => {
      if (key === 'externalUrl') {
        newFilters[key] = ''
      } else if (key === 'durationOperator') {
        newFilters[key] = '<'
      } else if (key === 'durationChoice') {
        newFilters[key] = ''
      } else {
        newFilters[key].forEach(filter => { filter.selected = false })
      }
    })
    setFilters(newFilters)
    // setFilters(returnDefaultFilters(anomaliesList))
    setNothingHasChanged(false)
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Popover
        open={openPopper}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        classes={{
          paper: classes.paper
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        TransitionProps={{
          onEnter: () => {
            setNothingHasChanged(true)
          }
        }}
      >
        {onFilterChange && filters && filters.identifier && filters.identifier.length > 0
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='body1'>Seleziona uno o più filtri e clicca su &quot;Applica&quot;.</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Categoria</FormLabel>
                  <FormGroup>
                    {filters.identifier.map((category, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={<Checkbox color='primary' checked={category.selected} onChange={(event) => handleCheckboxChange('identifier', index)} name={category.label} />}
                          label={category.label}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
                <FormControl fullWidth component='fieldset' style={{ marginTop: 8 }}>
                  <FormLabel component='legend'>Nome</FormLabel>
                  <FormGroup>
                    {filters.name.map((name, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={<Checkbox color='primary' checked={name.selected} onChange={(event) => handleCheckboxChange('name', index)} name={name.label} />}
                          label={<Tooltip title={name.label}><Typography style={{ maxWidth: 300 }} noWrap>{name.label}</Typography></Tooltip>}
                        />
                      )
                    })}
                    {filters.message.map((message, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={<Checkbox color='primary' checked={message.selected} onChange={(event) => handleCheckboxChange('message', index)} name={message.label} />}
                          label={<Tooltip title={message.label}><Typography style={{ maxWidth: 300 }} noWrap>{message.label}</Typography></Tooltip>}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
                <FormControl component='fieldset' style={{ width: '90%', marginTop: 8 }}>
                  <FormLabel component='legend'>Presenza link</FormLabel>
                  <TextField
                    style={{ marginTop: 8 }}
                    id='externalUrl'
                    size='small'
                    select
                    name='externalUrl'
                    // label='Presenza link'
                    placeholder='Scelta...'
                    value={filters.externalUrl}
                    onChange={(event) => {
                      alog('new url choice =>', event.target.value, 'anomalie')
                      setFilters({
                        ...filters,
                        externalUrl: event.target.value
                      })
                      setNothingHasChanged(false)
                    }}
                  // helperText="Please select your currency"
                  >
                    <MenuItem value=''><em>Nessuna scelta</em></MenuItem>
                    <MenuItem value>Con url esterno</MenuItem>
                    <MenuItem value={false}>Senza url esterno</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Severity</FormLabel>
                  <FormGroup>
                    {filters.severity.map((severity, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={<Checkbox color='primary' checked={severity.selected} onChange={(event) => handleCheckboxChange('severity', index)} name={severity.label} />}
                          label={severity.label}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Durata</FormLabel>
                  <TextField
                    style={{ marginTop: 8 }}
                    id='duration-operator'
                    size='small'
                    select
                    name='durationOperator'
                    // label="Select"
                    placeholder='scegliere operatore'
                    value={filters.durationOperator}
                    onChange={(event) => {
                      alog('new operator =>', event.target.value, 'anomalie')
                      setFilters({
                        ...filters,
                        durationOperator: event.target.value
                      })
                      setNothingHasChanged(false)
                    }}
                    // helperText="Please select your currency"
                  >
                    <MenuItem value='>'>Maggiore di</MenuItem>
                    <MenuItem value='<'>Minore di</MenuItem>
                  </TextField>
                  <RadioGroup
                    aria-label='Durata'
                    name='durationChoice'
                    value={filters.durationChoice}
                    // onChange={event => {
                    //   alog('new duration choice =>', event.target.value, 'anomalie')
                    //   setFilters({
                    //     ...filters,
                    //     durationChoice: event.target.value
                    //   })
                    //   setNothingHasChanged(false)
                    // }}
                  >
                    {filters.duration.map((duration, index) => {
                      return (
                        <FormControlLabel key={index} value={String(index)} control={<Radio onClick={handleRadioChange} color='primary' />} label={returnDuration(duration.label)} />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                  <Grid item>
                    <Button onClick={handleClosePopover} variant='outlined'>Chiudi</Button>
                    <Button onClick={handleResetFilters} variant='outlined' color='primary' style={{ marginLeft: 16 }}>Resetta</Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleApplyFilters} disabled={nothingHasChanged} variant='contained' color='primary'>Applica</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            )
          : (
            <Typography>Non ci sono filtri selezionabili</Typography>
            )}
      </Popover>
      <TableContainer style={{ height: anomaliesRows.length !== 0 && anomaliesRows[0] !== '-' ? '100%' : null }}>
        <PerfectScrollbar
            // onYReachStart={(container) => console.log(`onYReachStart container =>`, container)}
          onScrollY={(container) => {
            if (container.clientHeight + container.scrollTop === container.scrollHeight && !isGettingPagination) {
              // console.log('sono arrivato alla fine!')
              if (anomaliesRows.length < totalAnomalies) {
                if (getMoreAnomalies) {
                  setIsGettingPagination(true)
                  getMoreAnomalies(skip + limit)
                  setSkip(skip + limit)
                }
              } else {
                setEndPaginationReached(true)
                setTimeout(() => {
                  setEndPaginationReached(false)
                }, 4000)
              }
            }
          }}
          containerRef={ref => {
            if (ref) {
              // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
              // injecting a fix for this issue
              ref._getBoundingClientRect = ref.getBoundingClientRect

              ref.getBoundingClientRect = () => {
                const original = ref._getBoundingClientRect()

                return {
                  bottom: original.bottom,
                  left: original.left,
                  right: original.right,
                  top: original.top,
                  width: Math.round(original.width),
                  _width: original.width,
                  height: Math.round(original.height),
                  _height: original.height,
                  x: original.x,
                  y: original.y
                }
              }
            }
          }}
        >
          <Table stickyHeader className={classes.table} aria-labelledby='anomaliesTable' aria-label='Tabella Anomalie'>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox' style={{ borderLeft: `5px solid ${basicColors.primary}` }}>
                  <Tooltip title='Seleziona tutti' aria-label='Seleziona tutti' placement='top'>
                    <Checkbox
                      indeterminate={shouldBeIntermediate(anomaliesRows)}
                      checked={shouldAllBeChecked(anomaliesRows)}
                      onChange={onSelectAllAnomalies}
                      inputProps={{ 'aria-label': 'Select all anomalies' }}
                      color='primary'
                    />
                  </Tooltip>
                </TableCell>
                <TableCell style={{ minWidth: 105, cursor: 'pointer' }} onClick={openFilterPopper}>
                  {/* {filtersToSend.identifier && filtersToSend.identifier.length > 0
                    ? (
                      <span style={{ textDecoration: 'underline' }}>Categoria</span>
                      )
                    : 'Categoria'} */}
                  {filtersToSend.identifier && filtersToSend.identifier.length > 0
                    ? (
                      <Badge color='primary' badgeContent={filters.identifier.filter(cat => cat.selected === true).length}>
                        Categoria
                      </Badge>
                      )
                    : 'Categoria'}
                </TableCell>
                <TableCell style={{ minWidth: 220, cursor: 'pointer' }} onClick={openFilterPopper}>
                  {(filtersToSend.name && filtersToSend.name.length > 0) || (filtersToSend.message && filtersToSend.message.length > 0)
                    ? (
                      <Badge color='primary' badgeContent={(filtersToSend.name ? filtersToSend.name.length : 0) + (filtersToSend.message ? filtersToSend.message.length : 0)}>
                        Nome
                      </Badge>
                      )
                    : 'Nome'}
                </TableCell>
                <TableCell style={{ minWidth: 94, cursor: 'pointer' }} onClick={openFilterPopper} align='center'>
                  {filtersToSend.severity && filtersToSend.severity.length > 0
                    ? (
                      <Badge color='primary' badgeContent={filtersToSend.severity.length}>
                        Severity
                      </Badge>
                      )
                    : 'Severity'}
                </TableCell>
                <TableCell style={{ minWidth: 150, cursor: 'pointer' }} onClick={openFilterPopper}>Periodo</TableCell>
                <TableCell style={{ minWidth: 110, cursor: 'pointer' }} onClick={openFilterPopper} align='center'>
                  {filtersToSend.duration && filtersToSend.duration.value
                    ? (
                      <Badge color='primary' badgeContent={1}>
                        <Tooltip title={'Durata totale dell\'anomalia'} aria-label='Durata totale' interactive>
                          <Box display='flex' alignItems='center' justifyContent='center'>
                            <span style={{ marginRight: 6 }}>Durata</span>
                            <SvgIcon fontSize='small'>
                              <InfoIcon />
                            </SvgIcon>
                          </Box>
                        </Tooltip>
                      </Badge>
                      )
                    : (
                      <Tooltip title={'Durata totale dell\'anomalia'} aria-label='Durata totale' interactive>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                          <span style={{ marginRight: 6 }}>Durata</span>
                          <SvgIcon fontSize='small'>
                            <InfoIcon />
                          </SvgIcon>
                        </Box>
                      </Tooltip>
                      )}
                </TableCell>
                <TableCell style={{ minWidth: 90, cursor: 'pointer' }} onClick={openFilterPopper} align='center'>
                  {filtersToSend.externalUrl && filtersToSend.externalUrl === true
                    ? (
                      <Badge color='primary' badgeContent={1}>
                        Link
                      </Badge>
                      )
                    : 'Link'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {anomaliesRows[0] !== '-' &&
                  anomaliesRows
                    .sort((a, b) => {
                      return Number(b.severity[b.severity.length - 1].level) - Number(a.severity[a.severity.length - 1].level)
                    })
                    .sort((a, b) => {
                      if (a.endedAt && !b.endedAt) {
                        return 1
                      } else if (!a.endedAt && b.endedAt) {
                        return -1
                      } else {
                        return 0
                      }
                    })
                    .map((row, index) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={index}
                          style={{
                            backgroundColor: row.endedAt && row.endedAt !== '' ? basicColors.background : null
                          }}
                        >
                          <TableCell
                            padding='checkbox'
                            style={{
                              borderLeft: `5px solid ${row.subCategory?.color || basicColors.primary}`
                            }}
                          >
                            <Checkbox
                              checked={row.selected}
                              onClick={(event) => onSelectAnomaly(event, index)}
                              inputProps={{
                                'aria-labelledby': `anomalies-checkbox-${index}`
                              }}
                              color='primary'
                            />
                          </TableCell>
                          <TableCell onClick={(event) => handleClick(event, row)} className={classes.anomalyCell}>
                            {row.subCategory?.label}
                          </TableCell>
                          <TableCell onClick={(event) => handleClick(event, row)} className={classes.anomalyCell}>
                            {row.name}
                          </TableCell>
                          <TableCell
                            onClick={(event) => handleClick(event, row)}
                            className={classes.anomalyCell}
                            align='center'
                          >
                            {row.severity?.[(row.severity?.length ?? 0) - 1]?.level || '-'}
                          </TableCell>
                          <TableCell onClick={(event) => handleClick(event, row)} className={classes.anomalyCell}>
                            {moment(row.startedAt).format('DD/MM/yyyy HH:mm')}
                            {row.endedAt ? <br /> : null}
                            {row.endedAt ? moment(row.endedAt).format('DD/MM/yyyy HH:mm') : null}
                          </TableCell>
                          <TableCell
                            onClick={(event) => handleClick(event, row)}
                            className={classes.anomalyCell}
                            align='center'
                          >
                            {returnDuration(row.duration)}
                          </TableCell>
                          <TableCell
                            onClick={(event) => handleClick(event, row)}
                            className={classes.anomalyCell}
                            align='center'
                          >
                            <Tooltip
                              title={row.externalUrl && row.externalUrl !== '' ? 'Link esterno' : 'Nussun link presente'}
                              aria-label={row.externalUrl && row.externalUrl !== '' ? 'Link esterno' : 'Nussun link presente'}
                            >
                              <SvgIcon fontSize='small'>
                                <LinkIcon color={row.externalUrl && row.externalUrl !== '' ? basicColors.primary : basicColors.blueGrayMedium} />
                              </SvgIcon>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
            </TableBody>
          </Table>
          {isGettingPagination && (
            <Grid container justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 20 }}>
              <Grid style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.cicle} variant='indeterminate' size={40} thickness={3} />
              </Grid>
            </Grid>
          )}
          {endPaginationReached && (
            <Grid container justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 16, marginBottom: 20 }}>
              <Grid style={{ textAlign: 'center' }}>
                <Typography variant='body1' align='center'>
                  Non ci sono altre anomalie!
                </Typography>
              </Grid>
            </Grid>
          )}
        </PerfectScrollbar>
      </TableContainer>
      {anomaliesRows[0] === '-' && (
        <Grid container justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 20 }}>
          <Grid style={{ textAlign: 'center' }}>
            <LoadingCard />
          </Grid>
        </Grid>
      )}
      {anomaliesRows.length === 0 && (
        <Grid container justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 40 }}>
          <Grid style={{ textAlign: 'center' }}>
            <img
              src='/static/images/zero-anomalies-1.svg'
              style={{ width: '80%', marginBottom: 16 }}
              alt='Celebrate illustration'
            />
            <Typography variant='body1' align='center'>
              Non ci sono anomalie per il periodo selzionato!
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

AnomaliesListView.propTypes = {
  className: PropTypes.string,
  anomaliesList: PropTypes.array,
  getMoreAnomalies: PropTypes.func,
  totalAnomalies: PropTypes.number,
  limit: PropTypes.number,
  onRowClick: PropTypes.func,
  onSelectedChanged: PropTypes.func,
  periodHasChanged: PropTypes.bool,
  onFilterChange: PropTypes.func
  // resetFilters: PropTypes.func
}

AnomaliesListView.defaultProps = {
  anomaliesList: [],
  totalAnomalies: 0,
  periodHasChanged: true
  // anomaliesCategoryStatus: [],
}

export default AnomaliesListView
